import { createSlice } from "@reduxjs/toolkit";


// returns dev, beta, gamma, prod
const currentStage = 'prod';
let currentAppId = ''
let currentAppPage = 'Home'
let isCurrentAppAuthorized = null
let isPortalAdmin = false
let corpReportingQuarter = false
let userId = ''
let userCC = ''
let userFN = ''
let userEmail = ''
let loginRedirectPath = null
let organization = null

const initialState = {
    userId,
    userCC,
    userFN,
    userEmail,
    currentStage,
    currentAppId,
    currentAppPage,
    isCurrentAppAuthorized,
    isPortalAdmin,
    corpReportingQuarter,
    loginRedirectPath,
    organization
}

const userAuthSlice = createSlice({
    'name': 'userAuth',
    initialState,
    reducers: {
        setCurrentAppId(state, action) {
            state.currentAppId = action.payload
        },
        setTemplateId(state, action) {
            state.templateId = action.payload
        },
        setCurrentAppPage(state, action) {
            state.currentAppPage = action.payload
        },
        setCurrentAppAuthorization(state, action) {
            state.isCurrentAppAuthorized = action.payload
        },
        setCurrentUserId(state, action) {
            state.userId = action.payload
        },

        setCurrentFullName(state, action) {
            state.userFN = action.payload
        },

        setCurrentEmail(state, action) {
            state.userEmail = action.payload
        },
        setIsPortalAdmin(state, action) {
            state.isPortalAdmin = action.payload
        },

        setCorpReportingQuarter(state, action) {
            state.corpReportingQuarter = action.payload
        },
        setLoginRedirectPath(state, action) {
            state.loginRedirectPath = action.payload
        },
        setOrganization(state, action) {
            state.organization = action.payload
        }
    }
})

export const { setCurrentAppId, setCurrentAppPage, setCurrentAppAuthorization, setCurrentUserId, setCurrentFullName, setCurrentEmail, setIsPortalAdmin, setTemplateId, setCorpReportingQuarter, setLoginRedirectPath, setOrganization } = userAuthSlice.actions

export default userAuthSlice.reducer
