import {
    GetSecretValueCommand,
    GetSecretValueResponse,
    SecretsManagerClient,
    SecretsManagerClientConfig
  } from '@aws-sdk/client-secrets-manager';
  import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
  import { Auth } from 'aws-amplify';
  import { SECRET_KEY_ARN_CONFIG, GRAPHQL_CONFIG, MJE_GRAPHQL_CONFIG, S3_CONFIG, APIGATEWAY_CONFIG, QUICKSIGHT_DASHBOARD_CONFIG, MJE_S3_CONFIG, FRISCO_S3_CONFIG, LOGICO_S3_CONFIG, FLEXICO_GRAPHQL_CONFIG, FLEXICO_S3_CONFIG, APPSYNC_PLUS_GRAPHQL_CONFIG } from 'src/constants/AppConstants';

  export async function getToken() {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  }
  
  export async function getCredentialsFromAWSService() {
    const COGNITO_LOGIN: any = {};
    const REGION = 'us-west-2';
    const CognitoIdentityPoolId = 'us-west-2:eecb2ea9-4b9d-4b78-9388-8e284943dc7a';
    COGNITO_LOGIN[getCognitoDomain()] = await getToken();
    return fromCognitoIdentityPool({
      clientConfig: { region: REGION },
      identityPoolId: CognitoIdentityPoolId,
      logins: COGNITO_LOGIN
    });
  }
  
  function getCognitoDomain() {
    const REGION = 'us-west-2';
    const USER_POOL_ID = 'us-west-2_Bk4TcNAaa';
    return 'cognito-idp.' + REGION + '.amazonaws.com/' + USER_POOL_ID;
  }
  
  export const retrieveAPIKey = async (): Promise<GetSecretValueResponse> => {
    const REGION = 'us-west-2';
    const Stage = 'prod';
    const SECRET_MANAGER_ARN = SECRET_KEY_ARN_CONFIG.find((c) => c.environment === Stage)?.secretARN;
    const secretsManageClientConfig: SecretsManagerClientConfig = {
      region: REGION,
      credentials: await getCredentialsFromAWSService()
    };
    const secretsManagerClient = new SecretsManagerClient(secretsManageClientConfig);
    const command = new GetSecretValueCommand({ SecretId: SECRET_MANAGER_ARN });
    return await secretsManagerClient.send(command);
  };

  export const mjeRetrieveAPIKey = async (): Promise<GetSecretValueResponse> => {
    const REGION = 'us-west-2';
    const Stage = 'prod';
    const SECRET_MANAGER_ARN = SECRET_KEY_ARN_CONFIG.find((c) => c.environment === Stage)?.secretARN;
    const secretsManageClientConfig: SecretsManagerClientConfig = {
      region: REGION,
      credentials: await getCredentialsFromAWSService()
    };
    const secretsManagerClient = new SecretsManagerClient(secretsManageClientConfig);
    const command = new GetSecretValueCommand({ SecretId: SECRET_MANAGER_ARN });
    return await secretsManagerClient.send(command);
  };

  export const retrieveAGGridLicenseKey = async (): Promise<GetSecretValueResponse> => {
    const REGION = 'us-west-2';
    const Stage = 'prod';
    const SECRET_MANAGER_ARN = SECRET_KEY_ARN_CONFIG.find((c) => c.environment === Stage)?.secretARN;
    const secretsManageClientConfig: SecretsManagerClientConfig = {
      region: REGION,
      credentials: await getCredentialsFromAWSService()
    };
    const secretsManagerClient = new SecretsManagerClient(secretsManageClientConfig);
    const command = new GetSecretValueCommand({ SecretId: SECRET_MANAGER_ARN });
    return await secretsManagerClient.send(command);
  };

  export const getAppSyncConfig = async () => {
    try {
      const Stage = 'prod';
      const grapQLEndPoint = GRAPHQL_CONFIG.find((c) => c.environment === Stage)?.grapQLEndPoint;      
      return {
        Appsync: {
          aws_appsync_graphqlEndpoint: grapQLEndPoint, // TODO - will be replaced from aws secrets
          aws_appsync_region: 'us-east-1',
          aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
        }
      };
    } catch (error) {
      console.error('Unable to retrieve API Key ', error);
      throw error;
    }
  };

  export const getMJEAppSyncConfig = async () => {
    try {
      const Stage = 'prod';
      const apiKeySecretString = (await mjeRetrieveAPIKey()).SecretString;
      const grapQLEndPoint = MJE_GRAPHQL_CONFIG.find((c) => c.environment === Stage)?.grapQLEndPoint;
      let secretName = MJE_GRAPHQL_CONFIG.find((c) => c.environment === Stage)?.secretName;
      if (typeof(secretName) === 'undefined') {
        secretName = '';
      }
      const apiKeyFromSecrets = (apiKeySecretString && JSON.parse(apiKeySecretString)[secretName]) || '';
      // console.log(`Secret Values:`)
      // console.log(apiKeyFromSecrets)
      return {
        Appsync: {
          aws_appsync_graphqlEndpoint: grapQLEndPoint, // TODO - will be replaced from aws secrets
          aws_appsync_region: 'us-east-1',
          aws_appsync_authenticationType: 'API_KEY',
          aws_appsync_apiKey: apiKeyFromSecrets
        }
      };
    } catch (error) {
      console.error('Unable to retrieve API Key ', error);
      throw error;
    }
  };


  export const getFlexicoAppSyncConfig = async () => {
    try {
      const Stage = 'prod';
      const grapQLEndPoint = FLEXICO_GRAPHQL_CONFIG.find((c) => c.environment === Stage)?.grapQLEndPoint;
      return {
        Appsync: {
          aws_appsync_graphqlEndpoint: grapQLEndPoint, 
          aws_appsync_region: 'us-east-1',
          aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        }
      };
    } catch (error) {
      console.error('Unable to configure appsync ', error);
      throw error;
    }
  };

  export const getAppSyncPlusConfig = async () => {
    try {
      const Stage = 'prod';
      const grapQLEndPoint = APPSYNC_PLUS_GRAPHQL_CONFIG.find((c) => c.environment === Stage)?.grapQLEndPoint;
      return {
        Appsync: {
          aws_appsync_graphqlEndpoint: grapQLEndPoint, 
          aws_appsync_region: 'us-east-1',
          aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        }
      };
    } catch (error) {
      console.error('Unable to configure appsync ', error);
      throw error;
    }
  };

  export const getS3Config =  () => {
    try {
      const Stage = 'prod';

      const bucket = S3_CONFIG.find((c) => c.environment === Stage)?.bucket;
      const region = S3_CONFIG.find((c) => c.environment === Stage)?.region;
      return {
        S3: {
          aws_user_files_s3_bucket: bucket,
        aws_user_files_s3_bucket_region: region
        }
      };
    } catch (error) {
      console.error('Some Error Occured ', error);
      throw error;
    }
  };

  export const getMJES3Config =  () => {
    try {
      const Stage = 'prod';

      const bucket = MJE_S3_CONFIG.find((c) => c.environment === Stage)?.bucket;
      const region = MJE_S3_CONFIG.find((c) => c.environment === Stage)?.region;
      return {
        S3: {
          aws_user_files_s3_bucket: bucket,
        aws_user_files_s3_bucket_region: region
        }
      };
    } catch (error) {
      console.error('Some Error Occured ', error);
      throw error;
    }
  };

  export const getFriscoS3Config =  () => {
    try {
      const Stage = 'prod';

      const bucket = FRISCO_S3_CONFIG.find((c) => c.environment === Stage)?.bucket;
      const region = FRISCO_S3_CONFIG.find((c) => c.environment === Stage)?.region;
      return {
        S3: {
          aws_user_files_s3_bucket: bucket,
        aws_user_files_s3_bucket_region: region
        }
      };
    } catch (error) {
      console.error('Some Error Occured ', error);
      throw error;
    }
  };

  export const getLogicoS3Config =  () => {
    try {
      const Stage = 'prod';

      const bucket = LOGICO_S3_CONFIG.find((c) => c.environment === Stage)?.bucket;
      const region = LOGICO_S3_CONFIG.find((c) => c.environment === Stage)?.region;
      return {
        S3: {
          aws_user_files_s3_bucket: bucket,
        aws_user_files_s3_bucket_region: region
        }
      };
    } catch (error) {
      console.error('Some Error Occured ', error);
      throw error;
    }
  };

  export const getFlexicoS3Config =  () => {
    try {
      const Stage = 'prod';

      const bucket = FLEXICO_S3_CONFIG.find((c) => c.environment === Stage)?.bucket;
      const region = FLEXICO_S3_CONFIG.find((c) => c.environment === Stage)?.region;
      return {
        S3: {
          aws_user_files_s3_bucket: bucket,
        aws_user_files_s3_bucket_region: region
        }
      };
    } catch (error) {
      console.error('Some Error Occured ', error);
      throw error;
    }
  };

  export const getAPIGateWayConfig =  () => {
    try {
      const Stage = 'prod';

      const ApiEndpoint = APIGATEWAY_CONFIG.find((c) => c.environment === Stage)?.ApiGateWayEndpoint;
      return {
        APIGateWay: {
          ApiGateWayEndpoint: ApiEndpoint
        }
      };
    } catch (error) {
      console.error('Some Error Occured ', error);
      throw error;
    }
  };

  export const getQuicksightDashboardConfig =  () => {
    try {
      const Stage = 'prod';

      const url = QUICKSIGHT_DASHBOARD_CONFIG.find((c) => c.environment === Stage)?.url;
      return {
        dashboard: {
          url: url
        }
      };
    } catch (error) {
      console.error('Some Error Occured ', error);
      throw error;
    }
  };